import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { bemPrefix } from 'src/utils';
import { AppInteractionData, Platform, SelectedApps } from 'src/types/core';
import { InputCheckbox, Button, DropDown, Icon } from 'src/components/molecules';
import { sortBy, SortOrder } from 'src/utils/sort';
import { OrganizationApp } from 'src/reducers/organizations';

import './multi-apps..scss';

const bem = bemPrefix('multi-apps');

interface MonthlyActiveUsers {
  appId: string;
  mau: number;
}

interface MultiAppsProps {
  appId: string;
  platform: Platform;
  interactionData: AppInteractionData[];
  formRef?: React.RefObject<HTMLFormElement>;
  orgName: string;
  organizationApps: OrganizationApp[];
  monthlyActiveUsers: MonthlyActiveUsers[];
  selectedApps: (selectedApps: SelectedApps[]) => void;
}

const selectedOptions = [
  { value: 'All', label: 'All' },
  { value: Platform.iOS, label: 'iOS Apps' },
  { value: Platform.Android, label: 'Android Apps' },
  { value: Platform.Web, label: 'Websites' },
];

const columnTitles = [
  { title: 'App Name', key: 'title', sortable: true },
  { title: 'Type', key: 'platform', sortable: true },
  { title: 'Monthly Active Users', key: 'mau', sortable: true },
  { title: 'Latest SDK Version', key: 'latestSDK', sortable: false },
];

export const MultiApps: React.FC<MultiAppsProps> = ({
  appId = '',
  platform,
  interactionData = [],
  organizationApps,
  orgName,
  monthlyActiveUsers = [],
  selectedApps,
}) => {
  const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>([]);
  const [sortConfig, setSortConfig] = useState<{ key: keyof OrganizationApp; direction: SortOrder }>({
    key: 'title',
    direction: SortOrder.asc,
  });

  const currentApp = {
    appId,
    platform,
  };

  const dataApps = useMemo(() => {
    return organizationApps.map((app) => {
      const mauInfo = monthlyActiveUsers.find((user) => user.appId === app.id);
      const mauForWeb = -1;

      let formattedMau = null;
      if (app.platform === Platform.Web) {
        formattedMau = mauForWeb;
      }
      if (mauInfo) {
        formattedMau = Math.round(mauInfo.mau);
      }

      return {
        ...app,
        mau: formattedMau,
      };
    });
  }, [organizationApps, monthlyActiveUsers]);

  useEffect(() => {
    const isEmptyInteractionData = interactionData.find((interaction) => interaction.appId === '');

    if (isEmptyInteractionData) {
      selectedApps([currentApp]);
    }
  }, []);

  const onAppSelect = useCallback(
    (app: OrganizationApp) => {
      const isSelected = interactionData.some((interaction) => interaction.appId === app.id);
      let updatedSelectedApps: SelectedApps[] = interactionData.map(({ appId, platform }) => ({ appId, platform }));

      if (isSelected) {
        updatedSelectedApps = updatedSelectedApps.filter((interaction) => interaction.appId !== app.id);
      }
      if (!isSelected) {
        updatedSelectedApps = [...updatedSelectedApps, { appId: app.id, platform: app.platform }];
      }
      selectedApps(updatedSelectedApps);
    },
    [interactionData],
  );

  const disabledCheckBox = (app: OrganizationApp) => app.id === appId;

  const disabledClearBtn = interactionData.length === 1 ? 'disabled' : '';

  const onSelectAll = useCallback(
    (value: string) => {
      const allPlatform = 'All';
      let newSelectedPlatforms: string[] = selectedPlatforms;

      const isAllSelected = selectedPlatforms.includes(allPlatform);
      const isValueSelected = selectedPlatforms.includes(value);

      if (value === allPlatform) {
        newSelectedPlatforms = isAllSelected ? [] : [allPlatform];
      }

      if (value !== allPlatform && isValueSelected) {
        newSelectedPlatforms = newSelectedPlatforms.filter((platform) => platform !== value);
      }

      if (value !== allPlatform && !isValueSelected) {
        newSelectedPlatforms = newSelectedPlatforms.filter((platform) => platform !== allPlatform).concat(value);
      }

      setSelectedPlatforms(newSelectedPlatforms);

      let appsToSelect = organizationApps;
      const hasSelectedAll = newSelectedPlatforms.includes(allPlatform);

      if (!hasSelectedAll) {
        appsToSelect = organizationApps.filter((app) => newSelectedPlatforms.includes(app.platform));
      }

      const currentApp = organizationApps.find((app) => app.id === appId);
      if (currentApp) {
        const isAppSelected = appsToSelect.some((app) => app.id === appId);

        if (!hasSelectedAll && !isAppSelected && newSelectedPlatforms.length === 0) {
          appsToSelect = [currentApp];
        }

        if (!isAppSelected && (hasSelectedAll || newSelectedPlatforms.length > 0)) {
          appsToSelect = [currentApp, ...appsToSelect];
        }
      }

      const selectedAppsData: SelectedApps[] = appsToSelect.map((app) => ({
        appId: app.id,
        platform: app.platform,
      }));

      selectedApps(selectedAppsData);
    },
    [organizationApps, appId, selectedPlatforms],
  );

  const onClearSelection = useCallback(() => {
    selectedApps([currentApp]);
    setSelectedPlatforms([]);
  }, [currentApp]);

  const onSortClick = (key: keyof OrganizationApp) => {
    if (!columnTitles.find((column) => column.key === key)?.sortable) {
      return;
    }
    let direction: SortOrder = SortOrder.asc;
    if (sortConfig && sortConfig.key === key && sortConfig.direction === SortOrder.asc) {
      direction = SortOrder.desc;
    }
    setSortConfig({ key, direction });
  };

  const sortedApps = useMemo(() => {
    return sortBy(dataApps, sortConfig.key, sortConfig.direction);
  }, [dataApps, sortConfig]);

  return (
    <div className={bem()}>
      <h2 className={bem('title')}>Choose Apps</h2>
      <p className={bem('description')}>
        Choose apps that will see the prompt. You&apos;ll be able to set the targeting conditions for all the apps
        later.
      </p>
      <div className={bem('header')}>
        <span className={bem('group-name')}>{orgName}</span>
        <div className={bem('select-dropdown')}>
          <span className={bem('select-dropdown', 'select')}>Select apps...</span>
          <DropDown
            options={selectedOptions}
            onSelect={onSelectAll}
            selectedValue={selectedPlatforms}
            customIcon="arrowDown"
            customCloseDropdown
          />
        </div>
      </div>
      <table className={bem('table')}>
        <thead>
          <tr>
            {columnTitles.map((column) => (
              <th key={column.key}>
                <div
                  className={classNames(bem('table-title'), { [bem('table-title', 'sortable')]: column.sortable })}
                  onClick={() => onSortClick(column.key as keyof OrganizationApp)}
                >
                  {column.title}
                  {column.sortable && (
                    <Icon
                      name="arrowDown"
                      className={classNames(bem('sort-icon'), 'visible', {
                        [bem('sort-icon', 'active-asc')]:
                          sortConfig && sortConfig.key === column.key && sortConfig.direction === SortOrder.asc,
                        [bem('sort-icon', 'active-desc')]:
                          sortConfig && sortConfig.key === column.key && sortConfig.direction === SortOrder.desc,
                      })}
                    />
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedApps.map((app) => (
            <tr key={app.id}>
              <td className={bem('select-apps')}>
                <InputCheckbox
                  className={bem('input-checkbox')}
                  name="chooseApps"
                  onChange={() => onAppSelect(app)}
                  checked={interactionData.some((data) => data.appId === app.id)}
                  disabled={disabledCheckBox(app)}
                />
                <div className={bem('app-name')}>
                  <img className={bem('app-name', 'icon')} src={app.icon_url} alt={app.title} />
                  <span className={bem('app-name', 'title')}>{app.title}</span>
                </div>
              </td>
              <td>{app.platform}</td>
              <td>{app.mau === -1 ? 'N/A' : app.mau}</td>
              <td>-</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={4} className={bem('warning')}>
              *This interaction uses features that are incompatible with this app&apos;s SDK version. The prompt cannot
              be shown on this application.
            </td>
          </tr>
        </tfoot>
      </table>
      <div className={bem('clear-selection', disabledClearBtn)}>
        <Button onClick={onClearSelection} disabled={!!disabledClearBtn}>
          Clear Selection
        </Button>
      </div>
    </div>
  );
};

MultiApps.displayName = 'MultiApps';
